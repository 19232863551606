import Amplify, { Auth, API, graphqlOperation, Storage } from "aws-amplify"
import {
  Authenticator,
  SignIn,
  ConfirmSignUp,
  Greetings,
  SignUp,
  ForgotPassword,
} from "aws-amplify-react"
import CustomSignUp from "../../components/CustomSignUp"
import { listProjects } from "../../graphql/queries"

import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import { Router } from "@reach/router"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import awsExports from "../../aws-exports"
import { Hub } from "aws-amplify"
Amplify.configure(awsExports)

const SignupLink = props => (
  <div>
    {/* {props.authState} */}
    {props.authState === "signIn" && (
      <div>
        <button onClick={() => props.onStateChange("signUp")}>
          Sign Up here
        </button>
      </div>
    )}
  </div>
)

const Dashboard = (props) => {
  const [user, setUser] = useState(null)
  const [userPool, setUserPool] = useState(null)
  const [projects, setProjects] = useState([])

  useEffect(() => {
    getUser()
    props.addDraggableMarker([])
  }, [])

  async function getUser() {
    try {
      const user = await Auth.currentAuthenticatedUser()
      console.log("user: ", user)
      console.log(user.signInUserSession.accessToken.payload["cognito:groups"])
      // console.log("attributes: ", user.attributes)
      setUser(user)
      fetchProjects(user)
    } catch (err) {
      console.log("Error: ", err)
    }
  }

  Hub.listen("auth", data => {
    switch (data.payload.event) {
      case "signIn":
        // console.log("user signed in")
        getUser()
        break
      case "signUp":
        // console.log("user signed up")
        break
      case "signOut":
        // console.log("user signed out")
        break
      case "signIn_failure":
        // console.log("user sign in failed")
        break
      case "configured":
        // console.log("the Auth module is configured")
    }
  })

  async function fetchProjects(passedUser) {
    try {
      const projectData = await API.graphql(
        graphqlOperation(listProjects, {
          filter: { ownerId: { eq: passedUser.username } },
        })
      )
      const projects = projectData.data.listProjects.items
      await Promise.all(
        projects.map(async project => {
          if (project.image) {
            const image = await Storage.get(project.image)
            project.image = image
          }
          return project
        })
      )
      setProjects(projects)
      // console.log("projects: ", projects)
    } catch (err) {
      console.log("error fetching projects: ", err)
    }
  }
  const ProjectList = props => {
    // console.log("auth-state: ", props.authState)
    // console.log("auth-data: ", props.authData)

    if (props.authState === "signedIn" && user) {
      if (projects.length > 0) {
        return (
          <section>
            {projects.map((project, index) => (
              <ul key={project.id ? project.id : index}>
                <li>{project.title}</li>
                <li>{project.description}</li>
                {project.image && (
                  <li>
                    <img src={project.image} style={{ width: 400 }} />
                  </li>
                )}
              </ul>
            ))}
            <Link to="/partner/submit">Submit a project</Link>
          </section>
        )
      } else {
        return <Link to="/partner/submit">Submit a project</Link>
      }
    }
    return null
  }

  function handleAuthStateChange(state) {
    if (state === "signedIn") {
      // console.log("running handle auth change")
      getUser()
    }
  }
  return (
    <div>
      <div className="authenticator">
        <Authenticator
          hideDefault={true}
          onStateChange={handleAuthStateChange()}
          // signUpConfig={mySignUpConfig}
        >
          <Greetings />
          <Link to="/partner/profile">Your profile</Link>
          <ProjectList />
          <SignIn />
          {/* <CustomSignUp /> */}
          {/* <SignUp signUpConfig={mySignUpConfig} /> */}
          <CustomSignUp />
          <ConfirmSignUp />
          <ForgotPassword />
          <SignupLink />
        </Authenticator>
      </div>
      <SEO title="Contribute" />
      <Link to="/">Go back to the homdepage</Link>
    </div>
  )
}

export default Dashboard
